<!-- Código html -->
<template>
    
    <!-- Contenedor principal -->
    <div id="contenedor_principal">


            <div class="titulo">
                Web Service Pogen Data
            </div>

            <div class="parrafo">
                This document describes the how to leverage POGEN's web services, 
                this services are SOAP based and are use to integrate the customer's
                data regarding its location's entries into their own systems and 
                applications
            </div>

            <div class="parrafo">
                Information to consume the service:
                <ol>
                    <li>Host:https://www.pogendata.com/webservice</li>
                    <li>WSDL:https://www.pogendata.com/webservice?wsdl</li>
                    <li>User: The email used as username to log into www.pogendata.com</li>
                    <li>Password: Password user to access www.pogendata.com</li>
                    <li>Encoding: Must be UTF-8</li>
                </ol>
            </div>

            <div class="titulo1 mt2em">
                Authentication
            </div>

            <div class="parrafo">
                Method used for basic authentication, it relies on the user credentials
                user normally to log into the www.pogendata.com portal.
                The user and passoword must be sent into the request headers
                http.
            </div>

            <div class="parrafo">
                If authentication fails, the server will returns 
                SOAP-ENV:Fault Message, Error description
            </div>

            <div class="titulo1">
                Entries by date
            </div>

            <div class="parrafo">
                Method to fetch location's entries information group by location and date.
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li><strong>email</strong>: User's email</li>
                    <li><strong>password</strong>: Password</li>
                    <li><strong>from</strong>: Starting date (YYYY-MM-DD)</li>
                    <li><strong>to</strong>: Ending date (YYYY-MM-DD)</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>
                        Object array with the locations including properties 
                        plaza_id (location), fecha (date) and entries.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to fetch the entries between specific dates */
function consultarEntradas($usuario,$contrasenia,$inicio,$fin){

    
    /* Start try catch */
    try{
        
        /* Create SOAP client object */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                "trace"=>1,
                "login"=>$usuario,
                "password"=>$contrasenia
            )
        );

        /* Create parameters to be used */
        $parametros = array(
            "from" => $inicio,
            "to" => $fin
        );

        /* Execute call using query method */
        $respuesta = $clienteSoap->__soapcall('query',$parametros);

        /* Send response */
        return $respuesta;

    }catch(Exception $e){

        /* Display error on exception */
        echo "Falló función: " . $e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Import additional libraries
from zeep import Client
from requests.auth import HTTPBasicAuth
from requests import Session
from zeep.transports import Transport

# Method to fetch locations
def obtenerEntradas(usuario,contrasenia,inicio,fin):
    
    
    # Create session
    sesion = Session()

    # Add credentials
    sesion.auth = HTTPBasicAuth(usuario,contrasenia)

    # Create client and use credentials
    cliente = Client('https://www.pogendata.com/webservice?wsdl',transport=Transport(session=sesion))

    # Execute request
    respuesta = cliente.service.query(inicio,fin)

    # Send data
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Entries by hour
            </div>

            <div class="parrafo">
                Method used to fetch counting entries for the locations by hour.
                Information is grouped by location and date
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li><strong>email</strong>: User's email</li>
                    <li><strong>password</strong>: Password</li>
                    <li><strong>from</strong>: Starting date (YYYY-MM-DD HH:mm:ss)</li>
                    <li><strong>to</strong>: Ending date (YYYY-MM-DD HH:mm:ss)</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>
                        Object array with the locations with properties plaza_id (location), fecha (date), and entries
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to fectch counting entries by date, using a date range using the 
parameters user, password and dates 
inicio using format (YYYY-MM-DD HH:MM:SS)
to (YYYY-MM-DD HH:MM:SS) */
function consultarHoras($usuario,$contrasenia,$inicio,$fin){

    /* Start try catch */
    try{

        /* Create SOAP client object using url, user and password */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                'trace'=>1,
                'login'=>$usuario,
                'password'=>$contrasenia
            )
        );

        /* Prepare parameters */
        $parametros = array(
            'from'=>$inicio,
            'to'=>$fin
        );

        /* Execute request */
        $respuesta = $clienteSoap->__soapcall('hours',$parametros);

        /* Send response */
        return $respuesta;

    }catch(Exception $e){

        /* Display error on exception */
        echo "Falló en la función consultarHoras: " . $e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Import additional libraries
from zeep import Client
from requests.auth import HTTPBasicAuth
from requests import Session
from zeep.transports import Transport

# Method to fetch entries by date
def EntradasHoras(usuario,contrasenia,inicio,fin):
    
    # Create session
    sesion = Session()

    # Add credentials
    sesion.auth = HTTPBasicAuth(usuario,contrasenia)

    # Create client and use credentials
    cliente = Client("https://www.pogendata.com/webservice?wsdl",transport=Transport(session=sesion))

    # Execute request
    respuesta = cliente.service.hours(inicio,fin)

    # Send data
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Counting entries by date
            </div>

            <div class="parrafo">
                Method used to fetch counting entries for the locations by hour.
                Information is grouped by location and date
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li><strong>email</strong>: User's email</li>
                    <li><strong>password</strong>: Password</li>
                    <li><strong>from</strong>: Starting date (YYYY-MM-DD HH:mm:ss)</li>
                    <li><strong>to</strong>: Ending date (YYYY-MM-DD HH:mm:ss)</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>
                        Object array with properties plaza_id (location id), fecha (date) and entradas (entries).
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to fetch entries by date, requires user, password and starting and ending dates
dates parameters are inicio with format YYYY-MM-DD HH:MM:SS, and to with format YYYY-MM-DD HH:MM:SS */ 
function consultarAccesoPorHoras($usuario,$contrasenia,$inicio,$fin){

    /* Start try catch */
    try{
        /* Create SOAP Client */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                'trace' => 1,
                'login' => $usuario,
                'password' => $contrasenia
            )
        );

        /* Establish parameters */
        $parametros = array(
            'from' => $inicio,
            'to' => $fin
        );

        /* Execute request */
        $respuesta = $clienteSoap->__soapcall('hoursByAccess',$parametros);

        /* Sent data */
        return $respuesta;

    }catch(Exception $e){

        /* Display error */
        echo "Falló la función consultarAccesoPorHoras: ".$e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Import additional libraries
from zeep import Client
from requests import Session
from requests.auth import HTTPBasicAuth
from zeep.transports import Transport

# Method to fetch entries by date
def EntradasHorasAcceso(usuario, contrasenia, inicio, fin):

# Create session
    sesion = Session()

    # Add credentials
    sesion.auth = HTTPBasicAuth(usuario,contrasenia)

    # Create client and use credentials
    cliente = Client("https://www.pogendata.com/webservice?wsdl", transport=Transport(session=sesion))

    # Execute request
    respuesta = cliente.service.hoursByAccess(inicio,fin)

    # Send data
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                List of locations
            </div>

            <div class="parrafo">
                Method that returns a list of locations associated to the user
            </div>

            <div class="detalles">

                <div class="categoria">
                    Response:
                </div>

                <ul>
                    <li>
                        Object array with locations, id and name.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">PHP</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to fetch locations, requires user and password */
function consultarEstablecimientos($usuario,$contrasenia){
    
    /* Start try */
    try{
        
        /* Create soap client object */
        $clienteSoap = new SoapClient("https://www.pogendata.com/webservice?wsdl",
            array(
                'trace'=>1,
                'login'=>$usuario,
                'password'=>$contrasenia
            )
        );
        
        # Execute request
        $respuesta = $clienteSoap->establecimientos();

        /* respuesta variable contains the location's information */
        return $respuesta;

    }catch(Exception $e){

        /* Display  exception */
        echo "Falló función: " . $e->getMessage();

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Import additional libraries
from zeep import Client 
from zeep.transports import Transport
from requests.auth import HTTPBasicAuth
from requests import Session

# Method to fetch locations
def Establecimientos(usuario, contrasenia):

    # Create session
    sesion = Session()

    # Add credentials
    sesion.auth = HTTPBasicAuth(usuario, contrasenia)

    # Create client and use credentials
    cliente = Client("https://www.pogendata.com/webservice?wsdl", transport=Transport(session=sesion))

    # Execute request
    respuesta = cliente.service.establecimientos()

    # Send data
    return respuesta
                        </code>
                    </pre>                    
                </div>
            </div>


        <footer>
            ©2021 Pogen, sistemas@pogen.com
        </footer>                     

    </div>

</template>
<!-- Código vue -->
<script>

    export default{

        data(){
            return{
                pa:0
            }
        },

        methods:{            
            animarPaneles(indice){
                let clases = ['c_php','c_py'];
                this.pa = indice;
                let contador = 0;
                clases.forEach(cU=>{
                    let pa = document.getElementsByClassName(cU);
                    for(let c = 0; c < pa.length; c++){
                        pa[c].style.height = contador === indice ? '50vh' : '0vh';
                    }
                    contador++;
                })
            }
        }

    }

</script>


<!-- Esilo propio -->
<style scoped>
    .contieneTabs{
        display: flex;
    }
    .tab{
        width:10em;
        text-align: center;
        background-color: #ccc;
        padding:.3em 0em;
        border: solid 1px #888;
        cursor: pointer;
    }
    .pizarra{
        height: 50vh;
        margin-bottom: 3em;
    }
    .c_js, .c_php, .c_net, .c_py{
        top: 0em;
        left: 0em;
        height: 0em;
        width: 100%;
        overflow-y: scroll;
        color: white;
        transition:height .3s linear;
    }
    .c_php{
        height: 50vh;
    }
    .seleccionado{
        background-color: #555;
        color: white;
    }
    .titulo1 {
    margin-top: 1em;
    font-size: 1.2em;
    color: #5a5a5a;
    padding-left: 1em;
    font-weight: 500;
    }
    .parrafo {
    text-align: justify;
    text-indent: 1em;
    padding: 0.7em;
    font-size: 0.9em;
    color: #5a5a5a;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    }
    .detalles {
    margin-top: 1.5em;
    padding-left: 1.5em;
    color: #5a5a5a;
    font-size: 0.9em;
    }
    .categoria {
    font-size: 1em;
    font-weight: 400;
    }

    .mt2em {
    margin-top: 2em;
    }
    footer {
    margin-top: 5em;
    text-align: center;
    background-color: #363636;
    border-radius: 0 0 0.5em 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: white;
    font-weight: 500;
    font-size: 0.8em;
    }                    
</style>